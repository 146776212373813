import './scss/index.scss';

const scrollHandler = () => {
  const animationElms = document.querySelectorAll('[data-animation]');
  const offsetY = window.pageYOffset;

  // 画面描画 90%
  const windowHeight = window.innerHeight * 0.9;
  const handlerPosition = offsetY + windowHeight;

  animationElms.forEach((elm) => {
    const clientRect = elm.getBoundingClientRect();
    const elmPositionY = offsetY + clientRect.top;

    if (elmPositionY <= handlerPosition) {
      elm.classList.add('is-active-animation');
    }
  });
};

window.onload = window.onscroll = scrollHandler;

// header-menu
const menuButton = document.getElementById('header-menu-button');
const menuArea = document.getElementById('header-menu-area');
const headerLinks = document.querySelectorAll('.header .header__link');

menuButton.addEventListener('click', () => {
  menuButton.classList.toggle('is-active');
  menuArea.classList.toggle('is-active');
});

headerLinks.forEach((elm) => {
  elm.addEventListener('click', () => {
    menuButton.classList.remove('is-active');
    menuArea.classList.remove('is-active');
  });
});

// ダブルタップでの拡大制御
document.addEventListener(
  'dblclick',
  (e) => {
    e.preventDefault();
  },
  { passive: false }
);
